// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

import algoliasearch from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import { searchBox, hits, infiniteHits } from "instantsearch.js/es/widgets";

import "jquery";
import "bootstrap";
import "popper.js";
window.$ = $;



Rails.start();
Turbolinks.start();
ActiveStorage.start();

require("trix")
require("@rails/actiontext")

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

$(document).on("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();

  $(".js-toggle-billing-address").on("change", function() {
    if($(this).prop("checked")) {
      $(".js-billing-address").slideUp()
    } else {
      $(".js-billing-address").slideDown()
    }
  })

  $(".js-tab-link").on('click', function() {
    $(".js-tab-link-wrapper").removeClass("active")
    $(this).parent().addClass("active")
  })

  $(".js-quantity-plus").on("click", function() {
    let currentCount = parseInt($(".js-quantity-input").val()) || 0
    $(".js-quantity-input").val(currentCount + 1)
  })

  $(".js-quantity-minus").on("click", function() {
    let currentCount = parseInt($(".js-quantity-input").val()) || 0
    if (currentCount < 2) {
      currentCount = 2
    }
    $(".js-quantity-input").val(currentCount - 1)
  })

  if ($("#searchbox").length > 0) {
    const searchClient = algoliasearch(
      window.algoliaAppId,
      window.algoliaApiSearchKey
    );

    const indexName = window.indexName;

    const searchParam = getParameterByName("q");

    const search = instantsearch({
      indexName: indexName,
      routing: {
        stateMapping: {
          stateToRoute(uiState) {
            const indexUiState = uiState[indexName];
            return {
              q: indexUiState.query,
              page: indexUiState.page,
              turbolinks: true,
            };
          },
          routeToState(routeState) {
            return {
              [indexName]: {
                query: routeState.q,
                page: routeState.page,
              },
            };
          },
        },
      },
      searchClient,
      initialUiState: {
        [indexName]: {
          query: searchParam,
        },
      },
    });

    search.addWidgets([
      searchBox({
        container: "#searchbox",
        autofocus: true,
        placeholder: "Hier tippen...",
      }),

      infiniteHits({
        container: "#hits",
        cssClasses: "yolo",
        templates: {
          showMoreText: "Mehr Ergebnisse anzeigen",
          empty:
            "<div style='padding: 24px;'>Für dein Suchbegriff <q>{{ query }}</q> können wir leider keine passenden Treffer finden.<div>",
          item: `<a class="new-product-tile" href="/${window.i18nLocale}/products/{{{slug_de}}}">
          <div class="position-relative" style="overflow: hidden; padding-top: 100%; background: #f7dccc; height: 0;">
          {{#sale_price}}
          <span class="sale-badge">Sale</span>
          {{/sale_price}}
          {{#new_in}}
          <span class="new-badge">New</span>
          {{/new_in}}
              <img class="img-fluid new-product-image" style="position: absolute; top: 0; left: 0;" loading="lazy" src="{{{image_url}}}">
          </div>
          <span class="new-product-title">
          {{{_highlightResult.name.value}}}
          </span>
          <div class="qodef-m-star qodef--initial" style="fill: #cc723f; stroke: #cc723f;margin-bottom: 11px;">
              <svg class="star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><g><path d="M 20.756,11.768L 15.856,1.84L 10.956,11.768L0,13.36L 7.928,21.088L 6.056,32L 15.856,26.848L 25.656,32L 23.784,21.088L 31.712,13.36 z"></path></g></svg>
              <svg class="star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><g><path d="M 20.756,11.768L 15.856,1.84L 10.956,11.768L0,13.36L 7.928,21.088L 6.056,32L 15.856,26.848L 25.656,32L 23.784,21.088L 31.712,13.36 z"></path></g></svg>
              <svg class="star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><g><path d="M 20.756,11.768L 15.856,1.84L 10.956,11.768L0,13.36L 7.928,21.088L 6.056,32L 15.856,26.848L 25.656,32L 23.784,21.088L 31.712,13.36 z"></path></g></svg>
              <svg class="star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><g><path d="M 20.756,11.768L 15.856,1.84L 10.956,11.768L0,13.36L 7.928,21.088L 6.056,32L 15.856,26.848L 25.656,32L 23.784,21.088L 31.712,13.36 z"></path></g></svg>
              <svg class="star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><g><path d="M 20.756,11.768L 15.856,1.84L 10.956,11.768L0,13.36L 7.928,21.088L 6.056,32L 15.856,26.848L 25.656,32L 23.784,21.088L 31.712,13.36 z"></path></g></svg>
          </div>
          <div class="pprice">
              {{#sale_price}}
              <s>{{{price}}}€</s>&nbsp;&nbsp;<span style="color: #cc723f;">{{{sale_price}}}€</span>
              {{/sale_price}}
              {{^sale_price}}
              <span>{{{price}}}€</span>
              {{/sale_price}}
          </div>
        </a>
          
          `,
          old: "<div class='hallo' style='position:relative;'><div style='position: relative; padding-top: 75%; background: #eee; height: 0; margin-bottom: 8px;'><img style='position: absolute; top: 0; left: 0;' loading='lazy' alt='{{{image_title}}}' class='img-fluid shadow-sm' src='{{{image_url}}}'/></div><span class='new-product-title'>{{{_highlightResult.name_it.value}}} {{{_highlightResult.name.value}}} {{{_highlightResult.name_en.value}}}</span><a href='/de/products/{{{slug}}}' class='stretched-link'></a></div>",
        },
      }),
    ]);
    search.start();
  }
});